import { LitElement } from "lit";
import { property } from "lit/decorators.js";
import { app, router } from "../init";
import { toDateString, parseDateString } from "@pentacode/core/src/util";
import { HelpWidget } from "../elements/help";
import { DateRange } from "@pentacode/core/src/time";
import { DateString } from "@pentacode/openapi/src/units";

type Constructor<T> = new (...args: any[]) => T;

interface RoutePropertyDeclaration {
    name: string;
    arg?: string | number;
    param?: string | number;
    type?: StringConstructor | NumberConstructor | DateConstructor | BooleanConstructor | ObjectConstructor;
    default?: () => Exclude<any, undefined>;
    parse?: (inp: string) => string | number;
}

export type RouteArgs = Array<string | number | Date | boolean> & Record<string, string | number | Date | boolean>;

export function routeProperty(options: Omit<RoutePropertyDeclaration, "name">) {
    return (proto: object & { routeProperties: RoutePropertyDeclaration[] }, name: string) => {
        if (!proto.routeProperties) {
            proto.routeProperties = [];
        }
        proto.routeProperties.push({ ...options, name });
        return property(options)(proto, name);
    };
}

let resolveReady: (val: boolean) => void;
export const isReady = new Promise<boolean>((resolve) => (resolveReady = resolve));

export function ready() {
    resolveReady(true);
}

export const Routing = <T extends Constructor<LitElement>>(baseElement: T) => {
    class M extends baseElement {
        router = router;

        get routeTitle(): string | undefined {
            return undefined;
        }

        get helpPage(): string | undefined {
            return undefined;
        }

        routeProperties: RoutePropertyDeclaration[];

        @property({ type: Boolean, reflect: true })
        active: boolean = false;

        protected readonly routePattern: RegExp = /$^/;

        private _routeHandler = () => this.routeChanged(router.path, router.params);
        private _beforeRouteChangedHandler = (e: Event) => this.beforeRouteChanged(e);
        private _beforeUnloadHandler = (e: Event) => this.beforeUnload(e);

        private _visibilityChange = () => {
            if (document.visibilityState === "visible") {
                this.pageFocused();
            }
        };

        protected defaultRange?: () => DateRange;
        protected rangeChanged?: (range: DateRange) => void;

        @routeProperty({ param: "date" })
        date: DateString;

        @routeProperty({ param: "from" })
        dateFrom: DateString;

        @routeProperty({ param: "to" })
        dateTo: DateString;

        @routeProperty({ type: Number, param: "venue" })
        venueId: number;

        get dateRange(): DateRange | null {
            return this.dateFrom && this.dateTo
                ? {
                      from: this.dateFrom,
                      to: this.dateTo,
                  }
                : null;
        }

        get venue() {
            return app.getVenue(this.venueId);
        }

        get year() {
            return this.date && parseDateString(this.date)!.getFullYear();
        }

        shouldUpdate(changes: Map<string, any>) {
            return changes.has("active") || this.active;
        }

        updated(changes: Map<string, unknown>): any {
            if ((changes.has("dateFrom") || changes.has("dateTo")) && this.dateRange && this.rangeChanged) {
                this.rangeChanged(this.dateRange);
            }
        }

        get hasChanges(): boolean {
            return false;
        }

        clearChanges(): any {}

        connectedCallback() {
            super.connectedCallback();

            document.addEventListener("visibilitychange", this._visibilityChange);
            router.addEventListener("route-changed", this._routeHandler);
            router.addEventListener("before-route-changed", this._beforeRouteChangedHandler);
            window.addEventListener("beforeunload", this._beforeUnloadHandler);

            (async () => {
                await isReady;
                this._routeHandler();
            })();
        }

        disconnectedCallback() {
            super.disconnectedCallback();
            router.removeEventListener("route-changed", this._routeHandler);
            router.removeEventListener("before-route-changed", this._beforeRouteChangedHandler);
            window.removeEventListener("beforeunload", this._beforeUnloadHandler);
            document.removeEventListener("visibilitychange", this._visibilityChange);
        }

        go(
            path: string | null,
            params: { [param: string]: string | number | Date | undefined } = {},
            replace?: boolean
        ) {
            for (const [prop, value] of Object.entries(params)) {
                if (typeof value === "undefined") {
                    delete params[prop];
                } else if (typeof value === "number") {
                    params[prop] = value.toString();
                } else if (value instanceof Date) {
                    params[prop] = toDateString(value);
                }
            }

            router.go(path !== null ? path : router.path, params as { [param: string]: string }, replace);
        }

        redirect(path: string) {
            this.go(path, undefined, true);
        }

        protected matchRoute(path: string, params: { [param: string]: string }): null | RouteArgs {
            const match = path.match(this.routePattern);

            if (!match) {
                this.active = false;
                return null;
            }

            this.active = true;

            const groups = match.groups;
            const args = match.slice(1) as RouteArgs;
            Object.assign(args, groups);

            for (const prop of this.routeProperties || []) {
                let value = prop.arg ? args[prop.arg] : prop.param ? params[prop.param] : null;
                if (typeof value === "string") {
                    if (prop.parse) {
                        value = prop.parse(value);
                    }
                    this[prop.name as keyof typeof this] = (prop.type || String)(value);
                } else {
                    this[prop.name as keyof typeof this] = value as any;
                }
            }

            if (this.helpPage) {
                HelpWidget.suggestedHelpPage = this.helpPage;
            }

            return args;
        }

        protected routeChanged(path: string, params: { [prop: string]: string }): void {
            const args = this.matchRoute(path, params);

            if (args) {
                if (this.setDefaults()) {
                    return this.routeChanged(path, this.router.params);
                }

                this.handleRoute(args, params, path);

                if (this.routeTitle) {
                    document.title = `${this.routeTitle} | Pentacode`;
                }
            }
        }

        protected beforeRouteChanged(e: Event) {
            if (this.active && this.hasChanges) {
                if (
                    confirm(
                        "Sind Sie sicher, dass Sie diese Seite verlassen wollen? Eventuelle Änderungen werden verworfen."
                    )
                ) {
                    this.clearChanges();
                } else {
                    e.preventDefault();
                }
            }
        }

        protected beforeUnload(e: Event) {
            if (this.active && this.hasChanges) {
                e.preventDefault();
                e.returnValue = false;
            }
        }

        protected setDefaults() {
            const defaults: Record<string, any> = {};
            for (const prop of this.routeProperties || []) {
                if (prop.param && typeof this[prop.name as keyof typeof this] === "undefined" && prop.default) {
                    defaults[prop.param] = prop.default();
                }
            }

            if (this.defaultRange && !this.dateRange) {
                const range = this.defaultRange();
                defaults.from = range.from;
                defaults.to = range.to;
            }

            if (Object.entries(defaults).length) {
                router.params = { ...router.params, ...defaults };
                return true;
            }

            return false;
        }

        protected handleRoute(_args: RouteArgs, _params: { [prop: string]: string }, _path: string) {}

        protected pageFocused() {}
    }

    return M;
};
