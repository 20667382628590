import { html, css } from "lit";
import { customElement, query, state } from "lit/decorators.js";
import { Absence, TimeEntryType, timeEntryTypeColor, MonthlyStatement } from "@pentacode/core/src/model";
import { getRange, dateAdd, parseDateString, toDateString } from "@pentacode/core/src/util";
import { RequestAbsenceParams } from "@pentacode/core/src/api";
import { app } from "../../init";
import { Dialog } from "../dialog";
import { alert } from "../alert-dialog";
import { DateInput } from "../date-input";
import { DateRange } from "@pentacode/core/src/time";
import { DateString } from "@pentacode/openapi/src/units";

@customElement("ptc-staff-request-absence-dialog")
export class RequestAbsenceDialog extends Dialog<TimeEntryType, Absence> {
    @query("form")
    private _form: HTMLFormElement;

    @query("[name='start']")
    private _startInput: DateInput;

    @query("[name='end']")
    private _endInput: DateInput;

    @state()
    private _type: TimeEntryType.Vacation | TimeEntryType.Sick;

    @state()
    private _statements: MonthlyStatement[] = [];

    private get _employee() {
        return app.profile;
    }

    private get _data() {
        if (!this._form) {
            return;
        }

        const data = new FormData(this._form);
        return {
            start: data.get("start") as DateString,
            end: data.get("end") ? dateAdd(data.get("end") as DateString, { days: 1 }) : undefined,
            message: data.get("message") as string,
        };
    }

    private get _days() {
        if (!this._data) {
            return [];
        }
        const { start, end } = this._data;

        if (!start || !end) {
            return [];
        }

        const days: string[] = [];
        let date = start;

        while (date < end) {
            days.push(date);
            date = dateAdd(date, { days: 1 });
        }

        return days;
    }

    async show(type: TimeEntryType.Vacation | TimeEntryType.Sick) {
        this._type = type;
        const promise = super.show();
        this._lastRangeLoaded = undefined;
        this._load();
        await this.updateComplete;
        this._startInput.value = null;
        this._endInput.value = null;
        setTimeout(() => this._startInput.focus(), 500);
        return promise;
    }

    private _lastRangeLoaded?: DateRange;

    private async _load() {
        const data = this._data;

        if (!data || !data.start || !data.end || data.end < data.start) {
            this.requestUpdate();
            return;
        }

        const from = getRange(data.start, "month").from;
        const to = getRange(data.end, "month").to;

        if (this._lastRangeLoaded && from >= this._lastRangeLoaded.from && to <= this._lastRangeLoaded.to) {
            this.requestUpdate();
            return;
        }

        this.loading = true;
        try {
            const [statements] = await Promise.all([
                app.getMonthlyStatements({
                    employee: this._employee!.id,
                    from,
                    to,
                }),
                // app.getTimeEntries({
                //     employee: this._employee.id,
                //     from,
                //     to,
                //     type: [
                //         TimeEntryType.Work,
                //         TimeEntryType.Vacation,
                //         TimeEntryType.Sick,
                //         TimeEntryType.SickInKUG,
                //         TimeEntryType.ChildSick,
                //     ],
                // }),
            ]);
            this._statements = statements;
            this._lastRangeLoaded = { from, to };
        } catch (e) {}
        this.loading = false;

        this.requestUpdate();
    }

    private _isCommonDay(date: string) {
        const d = parseDateString(date)!;
        const contract = this._employee!.getContractForDate(date);
        if (contract && contract.fixedWorkDays) {
            return !!contract.hoursPerDay && contract.hoursPerDay[d.getDay()] !== 0;
        }
        const statement = this._statements.find((s) => s.year === d.getFullYear() && s.month === d.getMonth());
        return !!statement && statement.previousAverages?.commonWorkDays?.includes(d.getDay());
    }

    private async _submit(e: FocusEvent) {
        e.preventDefault();
        const { start, end, message } = this._data!;

        this.loading = true;
        try {
            const absence = await app.api.requestAbsence(
                new RequestAbsenceParams({ start, end: end!, message, type: this._type })
            );
            this.done(absence);
        } catch (e) {
            alert(e.message, { type: "warning" });
        }
        this.loading = false;
    }

    private _startChanged() {
        if (this._startInput.reportValidity()) {
            this._endInput.min = this._startInput.value!;
        }
    }

    static styles = [
        ...Dialog.styles,
        DateInput.styles,
        css`
            .inner {
                max-width: 30em;
            }
        `,
    ];

    renderContent() {
        const data = this._data;
        const type = this._type;
        const statements = [...new Map<number, MonthlyStatement>(this._statements.map((s) => [s.year, s])).values()];

        return html`
            <form @submit=${this._submit} class="padded" @input=${() => this.requestUpdate()}>
                <div class="padded margined relative">
                    <div class="big colored-text text-centering" style="--color-highlight: ${timeEntryTypeColor(type)}">
                        <i class="${app.localized.timeEntryTypeIcon(type)}"></i>
                        ${type === TimeEntryType.Vacation ? "Urlaub Beantragen" : "Krankmeldung"}
                    </div>
                </div>

                <div class="margined">
                    <div class="horizontal evenly stretching spacing layout">
                        <div class="vertical layout">
                            <label>Von</label>
                            <ptc-date-input
                                name="start"
                                required
                                @input=${this._load}
                                .min=${dateAdd(toDateString(new Date()), {
                                    days: this._type === TimeEntryType.Vacation ? 1 : 0,
                                })}
                                @change=${this._startChanged}
                                datePicker="popover"
                            ></ptc-date-input>
                        </div>
                        <div class="vertical layout">
                            <label>Bis</label>
                            <ptc-date-input
                                name="end"
                                required
                                @input=${this._load}
                                .min=${data && data.start}
                                @change=${(e: Event) => (e.target as DateInput).reportValidity()}
                                datePicker="popover"
                            ></ptc-date-input>
                        </div>
                    </div>

                    <div class="double-margined stats centering horizontal layout text-centering">
                        ${this._type === TimeEntryType.Vacation
                            ? statements.map((s) => {
                                  // If we're in the current year, account for carried over days
                                  const daysAvailable =
                                      s.year === new Date().getFullYear()
                                          ? s.vacation.nominalYear + s.vacation.carryYear - s.vacation.actualYear
                                          : s.vacation.nominalYear - s.vacation.actualYear;
                                  const daysRequired = this._days.reduce(
                                      (total, date) =>
                                          parseDateString(date)!.getFullYear() === s.year && this._isCommonDay(date)
                                              ? total + 1
                                              : total,
                                      0
                                  );
                                  if (!daysRequired) {
                                      return;
                                  }
                                  const daysLeft = Math.floor(daysAvailable - daysRequired);
                                  return html`
                                      <div class="horizontally-margined">
                                          <div class="semibold blue colored-text stats-label">Resturlaub ${s.year}</div>
                                          <div class="huge">
                                              ${Math.floor(daysAvailable)}
                                              <i class="smaller arrow-right"></i>
                                              <span class="colored-text ${daysLeft < 0 ? "red" : "green"}"
                                                  >${daysLeft}
                                              </span>
                                          </div>
                                      </div>
                                  `;
                              })
                            : ""}
                    </div>

                    <div>
                        <label>Bemerkungen</label>
                        <textarea name="message" class="fill-horizontally"></textarea>
                    </div>
                </div>
                <div class="padded spacing evenly stretching horizontal layout">
                    <button class="primary">Abschicken</button>
                    <button class="transparent" type="button" @click=${() => this.dismiss()}>Abbrechen</button>
                </div>
            </form>
        `;
    }
}
