import { guard } from "lit/directives/guard.js";
import { html, LitElement, TemplateResult } from "lit";
import { customElement, property, query } from "lit/decorators.js";
import { render } from "lit-html";
import { wait } from "@pentacode/core/src/util";
import "./scroller";

@customElement("ptc-virtual-list")
export class VirtualList<T> extends LitElement {
    @property({ attribute: false })
    data: T[] = [];

    @property({ type: Number })
    itemHeight: number;

    @property()
    renderItem: (data: T, index: number) => TemplateResult;

    @property({ type: Number })
    buffer: number = 2;

    @property()
    containItem = "none";

    @property()
    guard?: (data: T) => any[];

    get firstIndex() {
        return this._firstIndex;
    }
    get lastIndex() {
        return this._lastIndex;
    }

    @query("ptc-scroller")
    private _scroller: HTMLDivElement;

    private _firstIndex: number;
    private _lastIndex: number;
    private _height: number;
    private _canvasHeight: number;
    private _itemHeight: number;

    private _elements: {
        index: number;
        data: T | null;
        x: number;
        y: number;
    }[] = [];

    private _intersectionObserver = new IntersectionObserver((entries: IntersectionObserverEntry[]) =>
        this._intersectionHandler(entries)
    );

    private _intersectionHandler([entry]: IntersectionObserverEntry[]) {
        if (entry.isIntersecting) {
            this._updateBounds();
        }
    }

    connectedCallback() {
        super.connectedCallback();
        window.addEventListener("resize", () => this._updateBounds());
        this._intersectionObserver.observe(this);
    }

    firstUpdated() {
        this._scroller.addEventListener("scroll", () => this._updateIndizes(), { passive: true });
        this._updateBounds();
    }

    updated(changes: Map<string, any>) {
        if (changes.has("data") || changes.has("minItemWidth") || changes.has("itemHeight")) {
            this._updateBounds();
        }
    }

    scrollToIndex(index: number) {
        const { y } = this._getItemPosition(index);
        this._scroller.scrollTop = y;
        return this._updateIndizes();
    }

    private async _getItemHeight() {
        if (!this.data.length) {
            return 100;
        }

        const testEl = document.createElement("div");
        testEl.style.position = "absolute";
        testEl.style.opacity = "0";
        testEl.style.width = "300px";
        render(this.renderItem(this.data[0], 0), testEl);
        this.appendChild(testEl);
        await wait(50);
        const height = testEl.offsetHeight;
        testEl.remove();
        return height;
    }

    private async _updateBounds() {
        this._itemHeight = this.itemHeight || (await this._getItemHeight());
        const { height } = this.getBoundingClientRect();
        this._height = height;
        const rowCount = this.data.length;
        this._canvasHeight = rowCount * this._itemHeight;
        const elementCount = Math.ceil(this._height / this._itemHeight + 2 * this.buffer);

        const els = [];
        for (let i = 0; i < elementCount; i++) {
            els.push({ data: null, x: 0, y: 0, index: 0 });
        }
        this._elements = els;
        this._updateIndizes();
        this._updateElements();
    }

    private _updateIndizes() {
        const oldFirstIndex = this._firstIndex;
        const oldLastIndex = this._lastIndex;
        this._firstIndex = Math.max(Math.floor(this._scroller.scrollTop / this._itemHeight - this.buffer), 0);
        this._lastIndex = Math.min(this._firstIndex + this._elements.length, this.data.length) - 1;
        if (this._firstIndex !== oldFirstIndex || this._lastIndex !== oldLastIndex) {
            return this._updateElements();
        }
    }

    private _getItemPosition(i: number) {
        return {
            x: 0,
            y: Math.floor(i) * this._itemHeight,
        };
    }

    private async _updateElements() {
        for (let i = this._firstIndex; i <= this._lastIndex; i++) {
            const elIndex = i % this._elements.length;
            const { x, y } = this._getItemPosition(i);
            Object.assign(this._elements[elIndex], {
                index: i,
                data: this.data[i],
                x,
                y,
            });
        }
        this.requestUpdate();
        await this.updateComplete;
    }

    createRenderRoot() {
        return this;
    }

    render() {
        const { _itemHeight: h } = this;
        return html`
            <ptc-scroller class="fullbleed">
                <div
                    class="content"
                    style="position: relative; height: ${this
                        ._canvasHeight}px; padding: var(--virtual-list-padding, 0);"
                    role="presentation"
                >
                    ${this._elements.map(({ x, y, data, index }) => {
                        const render = () => {
                            return data !== null
                                ? html`
                                      <div
                                          class="virtual-row"
                                          style="position: absolute; will-change: transform; width: 100%; height: ${h}px; transform: translate3d(${x}px, ${y}px, 0); contain: ${this
                                              .containItem};"
                                          role="presentation"
                                          data-index=${index}
                                      >
                                          ${this.renderItem(data, index)}
                                      </div>
                                  `
                                : html``;
                        };

                        const deps = [x, y, data, h];
                        this.guard && data && deps.push(...this.guard(data));
                        return this.guard ? guard(deps, render) : render();
                    })}
                </div>
            </ptc-scroller>
        `;
    }
}
