import "./popover";
import "./employee-list";
import "./avatar";
import { LitElement, html } from "lit";
import { customElement, property, query } from "lit/decorators.js";
import { live } from "lit/directives/live.js";
import { app } from "../init";
import { EmployeeList } from "./employee-list";
import { Employee } from "@pentacode/core/src/model";
import { Popover } from "./popover";

@customElement("ptc-employee-select")
export class EmployeeSelect extends LitElement {
    @property()
    name: string;

    @property({ type: Boolean })
    required: boolean;

    @property({ type: Number })
    employeeId: number | null = null;

    @property()
    placeholder = "Mitarbeiter Wählen...";

    get employee() {
        return app.getEmployee(this.employeeId);
    }

    @query("ptc-employee-list")
    private _employeeList: EmployeeList;

    @query("ptc-popover")
    private _listPopover: Popover;

    private _employeeSelected(e: CustomEvent<{ employee: Employee }>) {
        this.employeeId = e.detail.employee.id;
        this._listPopover.hide();
    }

    createRenderRoot() {
        return this;
    }

    render() {
        return html`
            <div class="padded box relative click" tabindex="0" style="overflow: visible">
                <div class="spacing center-aligning horizontal layout">
                    <ptc-avatar class="tinier" .employee=${this.employee}></ptc-avatar>
                    <div class="stretch ellipsis">${this.employee?.name || this.placeholder}</div>
                    <i class="caret-down"></i>
                </div>

                <ptc-popover .preferAlignment=${["bottom"]} @popover-show=${() => this._employeeList.focus()}>
                    <ptc-employee-list
                        style="width: 20em; height: 30em;"
                        .employee=${live(this.employee)}
                        @change=${this._employeeSelected}
                    ></ptc-employee-list>
                </ptc-popover>
                <input
                    type="number"
                    name=${this.name}
                    ?required=${this.required}
                    .value=${live(this.employeeId?.toString() || "")}
                    class="fill"
                    style="position: absolute; top: 0; opacity: 0; box-sizing: border-box; pointer-events: none;"
                />
            </div>
        `;
    }
}
