import { Absence, AbsenceStatus, TimeEntryType, timeEntryTypeColor } from "@pentacode/core/src/model";
import {
    getRange,
    getDateArray,
    dateAdd,
    // dateSub,
    parseDateString,
    toDateString,
    formatDateShort,
    formatDate,
    dateSub,
    monthNames,
} from "@pentacode/core/src/util";
import { LitElement, html, css } from "lit";
import { customElement, property, state } from "lit/decorators.js";
import { classMap } from "lit/directives/class-map.js";
import { StateMixin } from "../../mixins/state";
import { Routing } from "../../mixins/routing";
import { singleton } from "../../lib/singleton";
import { app } from "../../init";
import { shared } from "../../styles";
import { alert } from "../alert-dialog";
import "../scroller";
import { RequestAbsenceDialog } from "./request-absence-dialog";
import { DateRange } from "packages/core/src/time";
import { DateString } from "packages/openapi/src/units";

@customElement("ptc-staff-absences")
export class Absences extends Routing(StateMixin(LitElement)) {
    routePattern = /^(home|absences)/;

    async connectedCallback() {
        super.connectedCallback();
        await app.loaded;
        if (app.account) {
            void this._load();
        }
    }

    @property({ reflect: true, type: Boolean })
    expanded = false;

    @state()
    private _loading = false;

    @state()
    private _absences: Absence[] = [];

    @state()
    private _displayPastAbsences = false;

    @singleton("ptc-staff-request-absence-dialog")
    private _requestAbsenceDialog: RequestAbsenceDialog;

    private get _employee() {
        return app.profile;
    }

    refresh() {
        return this._load();
    }

    private async _load() {
        if (!this._employee) {
            return;
        }

        this._loading = true;
        this._displayPastAbsences = false;

        try {
            this._absences = await app.api.getAbsences({ employee: this._employee.id });
        } catch (e) {
            void alert(e.message, { type: "warning" });
        }

        this._loading = false;
    }

    private async _newVacationRequest() {
        const absence = await this._requestAbsenceDialog.show(TimeEntryType.Vacation);
        if (absence) {
            void alert(
                "Dein Urlaubsantrag wurde erfolgreich an deinen Arbeitgeber übermittelt. Du wirst informiert, sobald dieser bearbeitet wurde.",
                { title: "Urlaubsantrag Gesendet", type: "success" }
            );
            void this._load();
        }
    }

    private async _reportSick() {
        const absence = await this._requestAbsenceDialog.show(TimeEntryType.Sick);
        if (absence) {
            void alert(
                "Deine Krankmeldung wurde erfolgreich an deinen Arbeitgeber übermittelt. Du wirst informiert, sobald diese bearbeitet wurde.",
                { title: "Krankmeldung Gesendet", type: "success" }
            );
            void this._load();
        }
    }

    static styles = [
        shared,
        css`
            :host {
                position: relative;
            }

            :host(:not([expanded])) .hide-when-collapsed {
                display: none;
            }

            .absence {
                border: solid 1px var(--shade-2);
                border-radius: 1em;
                padding-top: 0.5em;
                margin-top: 0;
            }

            .days-grid {
                display: grid;
                grid-template-columns: repeat(7, 1fr);
                grid-gap: 0.5em 0;
            }

            .day {
                text-align: center;
                height: 2em;
                box-sizing: border-box;
                position: relative;
            }

            .day.start,
            .day.middle,
            .day.end {
                border-top: solid 1px var(--color-highlight);
                border-bottom: solid 1px var(--color-highlight);
                background: var(--color-highlight);
                color: var(--color-bg);
            }

            .day.start {
                border-left: solid 1px var(--color-highlight);
                border-top-left-radius: 0.5em;
                border-bottom-left-radius: 0.5em;
            }

            .day.end {
                border-right: solid 1px var(--color-highlight);
                border-top-right-radius: 0.5em;
                border-bottom-right-radius: 0.5em;
            }

            .absence.denied .day,
            .absence.requested .day {
                color: var(--color-highlight);
                background: transparent;
            }

            .absence.denied .day:not(.before):not(.after)::after {
                content: "";
                display: block;
                width: 110%;
                height: 1px;
                background: currentColor;
                position: absolute;
                top: 0;
                bottom: 0;
                margin: auto;
                left: -5%;
            }

            .today {
                background: var(--color-highlight);
                color: var(--color-bg);
                border-radius: 100%;
                border: solid 1px;
                width: 1.5em;
                height: 1.5em;
                line-height: 1.5em;
                font-weight: bold;
            }

            .preview {
                --day-width: 0.2em;
            }

            .preview-inner {
                padding: 0.5em 2em;
            }

            .preview-timeline-month {
                box-sizing: border-box;
                border-top: solid 2px var(--shade-3);
                text-align: center;
                position: relative;
            }

            .preview-timeline-month::before {
                content: "";
                display: block;
                height: 1.5em;
                position: absolute;
                left: 0;
                top: -1em;
                border-left: solid 2px var(--shade-3);
            }

            .preview-absences {
                position: relative;
                height: 3.5em;
            }

            .preview-absence {
                position: absolute;
                background: var(--color-highlight);
                border-radius: 0.5em;
                top: 1.3em;
                height: 0.8em;
                overflow: visible;
                z-index: 1;
                border: solid 2px transparent;
            }

            .preview-absence.requested {
                background: var(--color-bg);
                border-color: var(--color-highlight);
            }

            .preview-absence-label {
                position: absolute;
                top: -1.8em;
                left: 50%;
                transform: translateX(-50%);
            }

            .preview-today {
                position: absolute;
                top: -2em;
                height: 3em;
                border-left: dashed 2px var(--orange);
            }

            .preview-timeline-week {
                position: absolute;
                top: -0.5em;
                height: 0.8em;
                border-left: solid 1px var(--shade-3);
            }
        `,
    ];

    private _renderOverview() {
        const today = toDateString(new Date());
        const from = getRange(today, "month").from;
        const to = dateAdd(from, { months: 12 });
        let first = getRange(from, "month").from;
        const months: DateRange[] = [];
        while (first < to) {
            months.push(getRange(first, "month"));
            first = dateAdd(first, { months: 1 });
        }
        const absences = this._absences.filter((a) => a.end > from && a.start < to);
        const mondays: DateString[] = [];
        let monday = getRange(from, "week").to;
        while (monday < to) {
            mondays.push(monday);
            monday = dateAdd(monday, { days: 7 });
        }

        return html`
            <div class="preview horizontal scroller hide-scrollbar">
                <div class="preview-inner" style="width: calc(${dateSub(from, to)} * var(--day-width))">
                    <div class="preview-absences relative">
                        ${absences.map(
                            (absence) => html`
                                <div
                                    class="preview-absence relative ${absence.status}"
                                    style="--color-highlight: ${timeEntryTypeColor(absence.type)}; left: calc(${dateSub(
                                        from,
                                        absence.start
                                    )} * var(--day-width)); width: calc(${dateSub(
                                        absence.start,
                                        absence.end
                                    )} * var(--day-width));"
                                >
                                    <div class="smaller bold preview-absence-label nowrap">
                                        ${formatDateShort(absence.start, true)} -
                                        ${formatDateShort(dateAdd(absence.end, { days: -1 }), true)}
                                    </div>
                                </div>
                            `
                        )}
                    </div>
                    <div class="preview-timeline horizontal layout bottom-margined relative">
                        ${months.map(({ from, to }, i) => {
                            const d = parseDateString(from)!;
                            return html`
                                <div
                                    class="preview-timeline-month semibold"
                                    style="width: calc(${dateSub(from, to)} * var(--day-width))"
                                >
                                    <span class="subtle">
                                        ${monthNames[d.getMonth()].slice(0, 3)}
                                        ${i === 0 || d.getMonth() === 0 ? d.getFullYear() : ""}
                                    </span>
                                </div>
                            `;
                        })}
                        ${mondays.map(
                            (date) => html`
                                <div
                                    class="preview-timeline-week"
                                    style="left: calc(${dateSub(from, date)} * var(--day-width))"
                                ></div>
                            `
                        )}
                        <div class="preview-today" style="left: calc(${dateSub(from, today)} * var(--day-width))"></div>
                    </div>
                </div>
            </div>
        `;
    }

    render() {
        const emp = this._employee;
        if (!emp) {
            return;
        }
        const today = toDateString(new Date());

        const absences = this._displayPastAbsences ? this._absences : this._absences.filter((a) => a.end > today);

        return html`
            <div class="fullbleed vertical layout">
                <div>${this._renderOverview()}</div>
                ${!absences.length
                    ? html`
                          <div class="stretch centering vertical layout">
                              <i class="giant faded island-tropical"></i>
                              <div>Es liegen aktuell keine geplanten Abwesenheiten vor.</div>
                              <button
                                  class="subtle double-margined"
                                  @click=${() => (this._displayPastAbsences = true)}
                                  ?hidden=${this._displayPastAbsences}
                              >
                                  Vergangene Abwesenheiten Anzeigen
                              </button>
                          </div>
                      `
                    : html`
                          <ptc-scroller class="stretch hide-when-collapsed">
                              <div
                                  class="centering small double-margined layout hide-when-collapsed"
                                  ?hidden=${this._displayPastAbsences}
                              >
                                  <button class="subtle" @click=${() => (this._displayPastAbsences = true)}>
                                      Vergangene Abwesenheiten Anzeigen
                                  </button>
                              </div>

                              ${absences.map((absence) => {
                                  const { start, end } = absence;
                                  const inclusiveEnd = dateAdd(end, { days: -1 });
                                  const renderFrom = getRange(start, "week").from;
                                  const renderTo = getRange(inclusiveEnd, "week").to;
                                  const dates = getDateArray(renderFrom, renderTo);

                                  return html`
                                      <div
                                          class="double-padded double-margined absence ${absence.status}"
                                          style="--color-highlight: ${timeEntryTypeColor(absence.type)}"
                                      >
                                          <div class="medium vertically-padded horizontal layout">
                                              <div class="bold stretch">
                                                  <span class="colored-text">
                                                      <i class="${app.localized.timeEntryTypeIcon(absence.type)}"></i>
                                                      ${app.localized.timeEntryTypeLabel(absence.type)}:
                                                  </span>
                                                  <strong>${formatDateShort(start)}</strong> -
                                                  <strong>${formatDateShort(inclusiveEnd)}</strong>
                                              </div>
                                              <div class="smaller">
                                                  ${absence.status === AbsenceStatus.Requested
                                                      ? html` <div class="orange pill">beantragt</div> `
                                                      : absence.status === AbsenceStatus.Denied
                                                        ? html` <div class="red pill">abgelehnt</div> `
                                                        : html`
                                                              <div class="pill">
                                                                  <strong>${absence.dayCount}</strong>
                                                                  ${absence.dayCount === 1 ? "Fehltag" : "Fehltage"}
                                                              </div>
                                                          `}
                                              </div>
                                          </div>

                                          ${absence.request
                                              ? html`
                                                    <div class="padded vertically-margined text-centering orange box">
                                                        <strong>
                                                            <i class="comment-question"></i> Beantragt am
                                                            ${formatDate(absence.request.time)}
                                                        </strong>
                                                        ${absence.request.message
                                                            ? html` - <span>"${absence.request.message}"</span> `
                                                            : ""}
                                                    </div>
                                                `
                                              : ""}
                                          ${absence.response
                                              ? html`
                                                    ${absence.status === AbsenceStatus.Denied
                                                        ? html`
                                                              <div
                                                                  class="padded vertically-margined text-centering red box"
                                                              >
                                                                  <strong>
                                                                      <i class="thumbs-down"></i> Abgelehnt von
                                                                      ${app.company?.getEmployee(
                                                                          absence.response.employeeId
                                                                      )?.name || "Unbekannt"}
                                                                      am ${formatDate(absence.response.time)}
                                                                  </strong>
                                                                  ${absence.response.message
                                                                      ? html`
                                                                            - <span>"${absence.response.message}"</span>
                                                                        `
                                                                      : ""}
                                                              </div>
                                                          `
                                                        : html`
                                                              <div
                                                                  class="padded vertically-margined text-centering green box"
                                                              >
                                                                  <strong>
                                                                      <i class="thumbs-up"></i> Genehmigt von
                                                                      ${app.company?.getEmployee(
                                                                          absence.response.employeeId
                                                                      )?.name || "Unbekannt"}
                                                                      am ${formatDate(absence.response.time)}
                                                                  </strong>
                                                                  ${absence.response.message
                                                                      ? html`
                                                                            - <span>"${absence.response.message}"</span>
                                                                        `
                                                                      : ""}
                                                              </div>
                                                          `}
                                                `
                                              : ""}

                                          <div class="days-grid">
                                              <div class="text-centering bold">Mo</div>
                                              <div class="text-centering bold">Di</div>
                                              <div class="text-centering bold">Mi</div>
                                              <div class="text-centering bold">Do</div>
                                              <div class="text-centering bold">Fr</div>
                                              <div class="text-centering bold">Sa</div>
                                              <div class="text-centering bold">So</div>

                                              ${dates.map(
                                                  (date) => html`
                                                      <div
                                                          class="day centering layout ${classMap({
                                                              before: date < start,
                                                              start: date === start,
                                                              middle: date > start && date < inclusiveEnd,
                                                              end: date === inclusiveEnd,
                                                              after: date > inclusiveEnd,
                                                          })}"
                                                      >
                                                          <div class="${date === today ? "today" : ""}">
                                                              ${parseDateString(date)!.getDate()}
                                                          </div>
                                                      </div>
                                                  `
                                              )}
                                          </div>
                                      </div>
                                  `;
                              })}
                          </ptc-scroller>
                      `}

                <div class="double-margined spacing evenly stretching horizontal layout hide-when-collapsed">
                    <button
                        class="ghost"
                        @click=${this._newVacationRequest}
                        style="--color-highlight: ${timeEntryTypeColor(TimeEntryType.Vacation)}"
                        ?hidden=${!app.hasPermission("staff.absences.request_vacation")}
                    >
                        <i class="${app.localized.timeEntryTypeIcon(TimeEntryType.Vacation)}"></i> Urlaub Beantragen
                    </button>

                    <button
                        class="ghost"
                        style="--color-highlight: ${timeEntryTypeColor(TimeEntryType.Sick)}"
                        @click=${this._reportSick}
                        ?hidden=${!app.hasPermission("staff.absences.request_sick")}
                    >
                        <i class="${app.localized.timeEntryTypeIcon(TimeEntryType.Sick)}"></i> Krank Melden
                    </button>
                </div>
            </div>

            <div class="fullbleed center-aligning center-justifying vertical layout scrim" ?hidden=${!this._loading}>
                <ptc-spinner ?active=${this._loading}></ptc-spinner>
            </div>
        `;
    }
}
