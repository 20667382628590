import { LitElement, html, css } from "lit";
import { customElement, state, query } from "lit/decorators.js";
import { clone } from "@pentacode/core/src/encoding";
import { Employee, NotificationType } from "@pentacode/core/src/model";
import { shared } from "../../styles";
import { StateMixin } from "../../mixins/state";
import { Routing, routeProperty } from "../../mixins/routing";
import { singleton } from "../../lib/singleton";
import { app } from "../../init";
import { CaptureImage } from "../capture-image";
import "../avatar";
import "../scroller";
import { alert, confirm } from "../alert-dialog";
import "../drawer";
import { Checkbox } from "../checkbox";

@customElement("ptc-staff-settings")
export class StaffSettings extends Routing(StateMixin(LitElement)) {
    static pages = ["account", "notifications"];

    readonly routePattern = /^settings(?:\/(?<page>[^/]+))?/;

    get routeTitle() {
        return "Einstellungen";
    }

    get hasChanges() {
        if (!this._data || !app.account || !app.profile) {
            return false;
        }

        if (this._updatingPassword) {
            return true;
        }

        const { avatar, email, notifications } = this._data;
        return (
            email !== (app.profile.email || "") ||
            avatar !== app.profile.avatar ||
            notifications.email.length !== app.profile.notifications?.email.length ||
            notifications.email.some((val) => !app.profile!.notifications?.email.includes(val))
        );
    }

    @state()
    private _loading = false;

    @state()
    private _profile: Employee;

    @routeProperty({ arg: "page" })
    private _page: string;

    @routeProperty({ param: "update_pw", type: Boolean })
    private _updatingPassword = false;

    @singleton("ptc-capture-image")
    private _captureImage: CaptureImage;

    @query("form")
    private _form: HTMLFormElement;

    @query("input[name='email']")
    private _emailInput: HTMLInputElement;

    @query("input[name='password']")
    private _passwordInput: HTMLInputElement;

    private get _data() {
        if (!this._form) {
            return null;
        }
        const data = new FormData(this._form);
        const email = data.get("email") as string;
        const avatar = data.get("avatar") as string;
        const password = data.get("password") as string;
        const repeatPassword = data.get("repeatPassword") as string;
        const emailNotifications = data.getAll("notifications.email") as NotificationType[];
        return { email, avatar, password, repeatPassword, notifications: { email: emailNotifications } };
    }

    async handleRoute() {
        if (!StaffSettings.pages.includes(this._page)) {
            this.redirect("settings/account");
            return;
        }
        this._profile = clone(app.profile!);
        await this.updateComplete;
        this._resetNotifications();
        if (this._updatingPassword) {
            setTimeout(() => (this._profile.email ? this._passwordInput.focus() : this._emailInput.focus()), 500);
        }
    }

    private _reset() {
        this.go(null, { update_pw: undefined });
        this._profile = clone(app.profile!);
        this._emailInput.value = this._profile.email;
        this._resetNotifications();
        this.requestUpdate();
    }

    private _resetNotifications() {
        for (const type of Object.values(NotificationType)) {
            const input = this.renderRoot!.querySelector(
                `input[name="notifications.email"][value="${type}"]`
            ) as HTMLInputElement;
            if (input) {
                input.checked = app.profile?.notifications?.email.includes(type) || false;
            }
        }
    }

    private async _editAvatar() {
        const avatar = await this._captureImage.show();
        if (avatar) {
            this._profile = Object.assign(clone(this._profile), { avatar });
            await this.updateComplete;
            this.requestUpdate();
        }
    }

    private async _deleteAvatar() {
        if (
            !(await confirm("Willst du dein Profilbild wirklich löschen", "Löschen", "Abbrechen", {
                title: "Profilbild Löschen",
                type: "destructive",
            }))
        ) {
            return;
        }

        this._profile = Object.assign(clone(this._profile), { avatar: "" });
        await this.updateComplete;
        this.requestUpdate();
    }

    private async _submitAccountSettings(e: Event) {
        e.preventDefault();

        if (!this._data) {
            return;
        }

        const { avatar, email, password, notifications } = this._data;

        const hadPassword = app.account!.hasPassword;
        this._loading = true;

        try {
            const profile: Partial<Employee> = {
                notifications,
            };
            if (avatar !== app.profile!.avatar) {
                profile.avatar = avatar;
            }
            await app.updateAccount({
                email,
                password: password || undefined,
                profile,
            });
            if (password) {
                if (hadPassword) {
                    alert("Passwort erfolgreich geändert!", { title: "Passwort Geändert", type: "success" });
                } else {
                    alert(
                        "Passwortzugang erfolgreich eingerichtet! Du kannst dich jetzt jederzeit mit deiner Email und deinem neuen Passwort in der Mitarbeiterapp anmelden!",
                        { title: "Passwortzugang Eingerichtet", type: "success" }
                    );
                }
            }
            this.go(null, { update_pw: undefined });
        } catch (e) {
            alert(e.message, { type: "warning" });
        }

        this._loading = false;
        this.handleRoute();
    }

    private async _logout() {
        const confirmed = await confirm("Bist du sicher dass Du dich ausloggen möchtest?", "Ausloggen", "Abbrechen", {
            title: "Ausloggen",
            icon: "sign-out",
        });
        if (confirmed) {
            await app.logout();
            window.location.reload();
        }
    }

    private _checkRepeatPassword(e: Event) {
        if (!this._data) {
            return;
        }
        const input = e.target as HTMLInputElement;
        const { password, repeatPassword } = this._data;

        input.setCustomValidity(password && password !== repeatPassword ? "Passwort stimmt nicht überein." : "");
    }

    static styles = [
        shared,
        Checkbox.styles,
        css`
            .notifications-grid {
                display: grid;
                grid-template-columns: 1fr auto;
                align-items: center;
            }
        `,
    ];

    render() {
        if (!app.account || !this._profile) {
            return;
        }
        return html`
            <div class="fullbleed vertical layout">
                <header class="bigger padded center-aligning horizontal layout">
                    <button class="small transparent" @click=${() => this.go("home")}>
                        <i class="chevron-left"></i>
                    </button>
                    <div class="larger left-margined stretch small-caps semibold">Einstellungen</div>
                    <button class="small subtle" @click=${this._logout}>
                        <i class="sign-out"></i>
                        Abmelden
                    </button>
                </header>

                <ptc-scroller class="stretch">
                    <form @submit=${this._submitAccountSettings} @change=${() => this.requestUpdate()}>
                        <div class="margined box">
                            <button
                                type="button"
                                class="larger small-caps fill-horizontally transparent text-left-aligning"
                                @click=${() => this.go("settings/account")}
                            >
                                <div class="horizontal center-aligning layout">
                                    <div class="stretch">Account</div>
                                    <i
                                        class="smaller ${this._page === "account" ? "chevron-down" : "chevron-right"}"
                                    ></i>
                                </div>
                            </button>
                            <ptc-drawer .collapsed=${this._page !== "account"}>
                                <div class="padded spacing vertical layout">
                                    <div>
                                        <label>Profilbild</label>
                                        <div class="horizontally-padded horizontal spacing center-aligning layout">
                                            <ptc-avatar .employee=${this._profile} class="huge"></ptc-avatar>

                                            <input type="hidden" name="avatar" .value=${this._profile.avatar} />

                                            <button type="button" @click=${this._editAvatar} class="stretch">
                                                <i class="pencil-alt"></i>
                                                Bild Wählen
                                            </button>

                                            <button
                                                type="button"
                                                class="negative stretch"
                                                @click=${this._deleteAvatar}
                                                ?hidden=${!this._profile.avatar}
                                            >
                                                <i class="trash"></i>
                                                Löschen
                                            </button>
                                        </div>
                                    </div>

                                    <div>
                                        <label>Email</label>
                                        <input
                                            type="email"
                                            name="email"
                                            placeholder="Emailadresse"
                                            .value=${app.account.email || this._profile.email}
                                            ?required=${this._updatingPassword}
                                            class="fill-horizontally"
                                        />
                                    </div>

                                    ${this._updatingPassword
                                        ? html`
                                              <div>
                                                  <label>Passwort</label>
                                                  <input
                                                      type="password"
                                                      name="password"
                                                      placeholder="Passwort Eingeben"
                                                      required
                                                      minlength="8"
                                                      class="fill-horizontally"
                                                  />
                                              </div>
                                              <div>
                                                  <label>Passwort Wiederholen</label>
                                                  <input
                                                      type="password"
                                                      name="repeatPassword"
                                                      placeholder="Passwort Wiederholen"
                                                      required
                                                      minlength="8"
                                                      @input=${this._checkRepeatPassword}
                                                      class="fill-horizontally"
                                                  />
                                              </div>
                                          `
                                        : html`
                                              <div>
                                                  <label>Passwort</label>
                                                  <div class="spacing vertical layout">
                                                      <div
                                                          class="horizontally-padded"
                                                          ?hidden=${app.account.hasPassword}
                                                      >
                                                          Richte jetzt einen Passwortzugang ein, damit du dich jederzeit
                                                          und von Überall anmelden kannst!
                                                      </div>
                                                      <button
                                                          type="button"
                                                          @click=${() => this.go(null, { update_pw: "1" })}
                                                      >
                                                          ${app.account.hasPassword
                                                              ? "Passwort Ändern"
                                                              : "Passwortzugang Einrichten"}
                                                      </button>
                                                  </div>
                                              </div>
                                          `}
                                    ${this.hasChanges
                                        ? html`
                                              <div class="horizontal spacing layout">
                                                  <button class="primary stretch">Speichern</button>
                                                  <button type="button" class="stretch" @click=${this._reset}>
                                                      Verwerfen
                                                  </button>
                                              </div>
                                          `
                                        : ""}
                                </div>
                            </ptc-drawer>
                        </div>
                    </form>
                </ptc-scroller>
            </div>

            <div class="fullbleed centering layout scrim" ?hidden=${!this._loading}>
                <ptc-spinner ?active=${this._loading}></ptc-spinner>
            </div>
        `;
    }
}
