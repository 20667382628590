import "./../roster-note";
import "./../scroller";
import { LitElement, html, css } from "lit";
import { customElement, property, state, query } from "lit/decorators.js";
import { TimeEntry, TimeEntryType, BreakMode, RosterNote } from "@pentacode/core/src/model";
import { hour } from "@pentacode/core/src/hours";
import {
    toDateString,
    toTimeString,
    dateAdd,
    formatTimeDistance,
    formatWeekDay,
    monthNames,
    toDurationString,
    displayDistinctWorkarea,
} from "@pentacode/core/src/util";
import { shared } from "../../styles";
import { StateMixin } from "../../mixins/state";
import { Routing } from "../../mixins/routing";
import { app, router } from "../../init";
import { singleton } from "../../lib/singleton";
import { QRScanner } from "../qr-scanner";
import { alert, confirm } from "../alert-dialog";
import { animations } from "../../styles";
import { GetRosterNotesParams } from "@pentacode/core/src/api";
import { Hours, add } from "@pentacode/openapi/src/units";
import { matchesFilters } from "@pentacode/core/src/filters";
import { getLocation, getLocationStatus } from "../../lib/util";
import { version } from "@pentacode/core/src/version";

@customElement("ptc-staff-tracking")
export class Tracking extends Routing(StateMixin(LitElement)) {
    routePattern = /^(home|tracking)/;

    @property({ reflect: true, type: Boolean })
    expanded = false;

    @state()
    private _timeEntries: TimeEntry[] = [];

    @state()
    private _rosterNotes: RosterNote[] = [];

    @state()
    private _loading = false;

    @singleton("ptc-qr-scanner")
    private _qrScanner: QRScanner;

    @query("#unplannedShiftForm")
    private _unplannedShiftForm: HTMLFormElement;

    private get _upcoming() {
        return this._timeEntries.find(
            (timeEntry) =>
                !timeEntry.startFinal &&
                timeEntry.start > new Date() &&
                app.getTimeSettings({ timeEntry }).trackingEnabled
        );
    }

    private get _startable() {
        return this._timeEntries.find((timeEntry) => {
            const { trackingEnabled, checkinWindow } = app.getTimeSettings({ timeEntry });
            const earliestCheckin = new Date(Date.now() + checkinWindow * hour);
            return (
                trackingEnabled &&
                !timeEntry.startFinal &&
                (!timeEntry.startPlanned || timeEntry.start < earliestCheckin) &&
                (!timeEntry.endPlanned || timeEntry.end > new Date())
            );
        });
    }

    private get _active() {
        return this._timeEntries.find((timeEntry) => {
            const { trackingEnabled, checkoutWindow } = app.getTimeSettings({ timeEntry });
            const latestCheckout = new Date(Date.now() - checkoutWindow * hour);
            return trackingEnabled && !!timeEntry.startFinal && !timeEntry.endFinal && timeEntry.start > latestCheckout;
        });
    }

    private get _recent() {
        const recent = this._timeEntries.filter(
            (timeEntry) =>
                app.getTimeSettings({ timeEntry }).trackingEnabled &&
                !!timeEntry.endFinal &&
                timeEntry.end.getTime() < Date.now() &&
                Date.now() - timeEntry.end.getTime() < 10 * 60 * 1000
        );
        return recent.sort((a, b) => Number(b.end) - Number(a.end))[0] || null;
    }

    private get _isTokenValid() {
        return router.params.tat && app.company!.tempAuthTokens!.includes(router.params.tat);
    }

    async connectedCallback() {
        super.connectedCallback();
        await app.loaded;
        this._load(true);
        setInterval(() => this.requestUpdate(), 1000 * 60);
        setInterval(() => this._load(), 1000 * 60 * 60);
    }

    updated(changes: Map<string, any>) {
        if (changes.has("expanded") && this.expanded) {
            this._load();
        }
    }

    refresh() {
        return this._load();
    }

    private async _load(showSpinner = false) {
        const today = toDateString(new Date());
        const from = dateAdd(today, { days: -1 });
        const to = dateAdd(today, { days: 2 });
        if (showSpinner) {
            this._loading = true;
        }

        try {
            const [entries, rosterNotes] = await Promise.all([
                app.getTimeEntries({
                    from,
                    to,
                    type: [TimeEntryType.Work],
                }),
                app.api.getRosterNotes(
                    new GetRosterNotesParams({
                        from,
                        to,
                    })
                ),
                app.fetchAccount(),
            ]);
            this._timeEntries = entries.filter((e) => !e.deleted);
            this._rosterNotes = rosterNotes;
        } catch (e) {
            alert(e.message, { type: "warning" });
        }

        if (showSpinner) {
            this._loading = false;
        }
    }

    private async _save(entry: TimeEntry, applyAutoMeals = false) {
        this._loading = true;
        try {
            await app.createOrUpdateTimeEntries(entry, { wait: true, applyAutoMeals, otherEntries: this._timeEntries });
            this.dispatchEvent(new CustomEvent("time-entry-updated", { detail: { entry } }));
            await this._load();
        } catch (e) {
            alert(e.message, { type: "warning" });
        }
        this._loading = false;
    }

    private async _fetchAccount() {
        this._loading = true;
        try {
            await app.fetchAccount();
        } catch (e) {
            alert(e.message, { type: "warning" });
        }
        this._loading = false;
    }

    private async _updateLocation() {
        const locationStatus = await getLocationStatus();

        if (locationStatus === "available") {
            await alert(
                "Laut den Zeiterfassungseinstellungen ist für diese Schicht das Erfassen Ihres aktuellen Standortes erforderlich. Bitte bestätigen Sie die folgende Berechtigungsabfrage!",
                { title: "Standortbestimmung", icon: "map-pin", options: ["Weiter"] }
            );
        }

        this._loading = true;
        try {
            const location = await getLocation();

            app.update({
                clientInfo: {
                    version,
                    userAgent: navigator.userAgent,
                    currentUrl: window.location.href,
                    screenWidth: window.screen.width,
                    screenHeight: window.screen.height,
                    location,
                    locationStatus,
                },
            });
        } catch (e) {
            await alert(
                "Leider konnte Ihr Standort nicht bestimmt werden. Bitte stellen Sie sicher, dass die Standorterfassung für diese App in Ihren Browsereinstellungen aktiviert ist.",
                { icon: "map-pin", title: "Standortbestimmung Fehlgeschlagen" }
            );
            throw e;
        } finally {
            this._loading = false;
        }
    }

    private async _startShift(entry = this._startable, requireConfirmation = true) {
        if (!entry) {
            return;
        }

        if (
            requireConfirmation &&
            !(await confirm("Möchtest du diese Schicht wirklich beginnen?", "Schicht Beginnen", "Abbrechen", {
                title: "Schicht Beginnen",
                icon: "play-circle",
                optionsLayout: "horizontal",
            }))
        ) {
            return;
        }

        await this._fetchAccount();

        const logged = new Date();
        const { startPlanned } = entry;

        const {
            checkinRounding,
            applyEarlyCheckin,
            trackingViaAppRequiresToken,
            trackingViaAppEnabled,
            loggingRequiresLocation,
        } = app.getTimeSettings({ timeEntry: entry });

        if (!trackingViaAppEnabled) {
            alert("Das Stempeln über die Mitarbeiter-App ist für diese Schicht nicht erlaubt!", {
                type: "warning",
                title: "Stempeln Nicht Möglich",
            });
            return;
        }

        if (trackingViaAppRequiresToken && !this._isTokenValid && !(await this._scanQR())) {
            return;
        }

        if (loggingRequiresLocation) {
            await this._updateLocation();
        }

        // If logged time is earlier than planned and applyEarlyCheckin setting
        // is not set, use planned time instead
        let final =
            startPlanned && logged < startPlanned && !applyEarlyCheckin ? new Date(startPlanned) : new Date(logged);

        // Apply rounding
        if (checkinRounding) {
            const minutes = final.getMinutes();
            const roundedMinutes = minutes - (minutes % checkinRounding) + Math.max(0, checkinRounding);
            final.setMinutes(roundedMinutes);
        }

        // Round to full minute
        final.setSeconds(0);
        final.setMilliseconds(0);

        // Dont round over planned?. time
        if (
            (startPlanned && final > startPlanned && logged <= startPlanned) ||
            (startPlanned && final < startPlanned && logged >= startPlanned)
        ) {
            final = new Date(startPlanned);
        }

        entry.startLogged = logged;
        entry.startFinal = final;

        if (toDateString(final) !== entry.date) {
            entry.date = toDateString(final);
        }

        await this._save(entry);
    }

    private async _endShift(entry = this._active) {
        if (!entry) {
            return;
        }

        if (
            !(await confirm("Möchtest Du diese Schicht wirklich beenden?", "Schicht Beenden", "Abbrechen", {
                title: "Schicht Beenden",
                icon: "door-open",
                optionsLayout: "horizontal",
            }))
        ) {
            return;
        }

        await this._fetchAccount();

        const { applyLateCheckout, checkoutRounding, trackingViaAppRequiresToken, loggingRequiresLocation } =
            app.getTimeSettings({
                timeEntry: entry,
            });

        if (trackingViaAppRequiresToken && !this._isTokenValid && !(await this._scanQR())) {
            return;
        }

        if (loggingRequiresLocation) {
            await this._updateLocation();
        }

        const logged = new Date();
        const { startFinal, endPlanned } = entry;

        if (!startFinal) {
            throw "Employee needs to check in first!";
        }

        // If logged time is later than planned and applyLateCheckout setting
        // is not set, use planned time instead
        let final = endPlanned && logged > endPlanned && !applyLateCheckout ? new Date(endPlanned) : new Date(logged);

        // Apply rounding
        if (checkoutRounding) {
            const minutes = final.getMinutes();
            const roundedMinutes = minutes - (minutes % checkoutRounding) + Math.max(0, checkoutRounding);
            final.setMinutes(roundedMinutes);
        }

        // Round to full minute
        final.setSeconds(0);
        final.setMilliseconds(0);

        // Dont round over planned time
        if (
            (endPlanned && final > endPlanned && logged <= endPlanned) ||
            (endPlanned && final < endPlanned && logged >= endPlanned)
        ) {
            final = new Date(endPlanned);
        }

        // Make sure end time is not earlier than start time
        if (final < startFinal) {
            final = startFinal;
        }

        entry.endLogged = logged;
        entry.endFinal = final;

        await this._save(entry, true);
    }

    private async _startBreak(entry = this._active) {
        if (!entry || entry.startBreak) {
            return;
        }

        if (
            !(await confirm("Sind Sie sicher dass Sie eine Pause beginnen möchten?", "Pause Beginnen", "Abbrechen", {
                title: "Pause Beginnen",
                icon: "coffee",
                optionsLayout: "horizontal",
            }))
        ) {
            return;
        }

        await this._fetchAccount();

        const { trackingViaAppRequiresToken, loggingRequiresLocation } = app.getTimeSettings({ timeEntry: entry });

        if (trackingViaAppRequiresToken && !this._isTokenValid && !(await this._scanQR())) {
            return;
        }

        if (loggingRequiresLocation) {
            await this._updateLocation();
        }

        entry.startBreak = new Date();
        await this._save(entry);
    }

    private async _endBreak(entry = this._active) {
        if (!entry || !entry.startBreak) {
            return;
        }

        if (
            !(await confirm("Sind Sie sicher dass Sie die Pause beenden möchten?", "Pause Beenden", "Abbrechen", {
                title: "Pause Beenden",
                icon: "coffee",
                optionsLayout: "horizontal",
            }))
        ) {
            return;
        }

        await this._fetchAccount();

        const { trackingViaAppRequiresToken, loggingRequiresLocation } = app.getTimeSettings({ timeEntry: entry });

        if (trackingViaAppRequiresToken && !this._isTokenValid && !(await this._scanQR())) {
            return;
        }

        if (loggingRequiresLocation) {
            await this._updateLocation();
        }

        const dur = ((Date.now() - entry.startBreak.getTime()) / hour) as Hours;
        entry.breakLogged = add(entry.breakLogged || (0 as Hours), dur);
        entry.startBreak = null;
        await this._save(entry);
    }

    private async _scanQR(): Promise<boolean> {
        const rawUrl = await this._qrScanner.show({
            message: html`<strong>Scanne den QR-Code</strong> an der <strong>digitalen Stempeluhr</strong> um
                fortzufahren!`,
        });
        if (!rawUrl) {
            return false;
        }
        let tempAuthToken: string | undefined = undefined;
        try {
            const url = new URL(rawUrl);
            tempAuthToken = url.searchParams.get("tat") as string | undefined;
        } catch (e) {}

        if (!tempAuthToken || !app.company!.tempAuthTokens || !app.company!.tempAuthTokens.includes(tempAuthToken)) {
            const tryAgain = await confirm(
                `${tempAuthToken ? "Abgelaufener" : "Ungültiger"} QR-Code! Bitte versuchen Sie es erneut!`,
                "Erneut Versuchen",
                "Abbrechen",
                { title: "QR-Code Scannen", type: "warning" }
            );
            if (tryAgain) {
                return this._scanQR();
            }
            return false;
        }

        router.go(undefined, { tat: tempAuthToken });
        return true;
    }

    private async _submitUnplannedShift(e: Event) {
        e.preventDefault();

        if (
            !(await confirm("Möchtest Du wirklich eine spontane Schicht beginnen?", "Bestätigen", "Abbrechen", {
                title: "Spontane Schicht",
                icon: "running",
            }))
        ) {
            return;
        }

        const data = new FormData(this._unplannedShiftForm);
        const posId = data.get("position") as string;
        const { position } = app.getPosition(Number(posId))!;
        const entry = new TimeEntry({
            employeeId: app.profile!.id,
            date: toDateString(new Date()),
            position,
        });
        entry.setPublished();
        await this._startShift(entry, false);
    }

    static styles = [
        shared,
        animations,
        css`
            :host {
                display: block;
                position: relative;
            }

            .grid {
                --grid-gap: 1em;
            }

            .hide-when-collapsed {
                transition: opacity 0.3s;
            }

            :host(:not([expanded])) .hide-when-collapsed {
                display: none;
            }

            .time-display {
                margin-bottom: -0.25em;
                font-weight: 600;
            }

            .wrapper {
                padding: 0 1em 1em 1em;
            }

            .timer {
                padding-right: 0.25em;
            }

            .timer .coffee {
                transform: scale(0.85);
            }
        `,
    ];

    private _renderRosterNotes() {
        const today = toDateString(new Date());
        const company = app.company;
        const employee = app.profile;

        if (!company || !employee) {
            return;
        }

        const notes = this._rosterNotes.filter(
            (note) => matchesFilters(note.filters, { company, employee }) && note.start <= today && note.end > today
        );

        return !notes.length
            ? ""
            : html`
                  <div class="wrapper hide-when-collapsed">
                      <div class="subtle horizontally-padded semibold text-centering small-caps">
                          <i class="sticky-note"></i> Dienstplannotizen
                      </div>
                      ${notes.map(
                          (note) =>
                              html`<ptc-roster-note
                                  .rosterNote=${note}
                                  .employee=${app.profile!}
                                  class="top-margined"
                              ></ptc-roster-note>`
                      )}
                  </div>
              `;
    }

    private _renderUpcoming(entry: TimeEntry) {
        if (!app.company || !app.profile || !entry.position) {
            return;
        }
        const { checkinWindow } = app.getTimeSettings({ timeEntry: entry });
        const earliestCheckin = new Date(entry.start!.getTime() - checkinWindow * hour);
        const availablePositions = app.getUnplannedShiftPositions(app.profile!);

        return html`
            <div class="wrapper spacing vertical layout">
                <div class="subtle horizontally-padded semibold text-centering small-caps">
                    <i class="calendar"></i> Nächster Einsatz
                </div>

                <div class="large padded box preview" style="--color-highlight: ${app.getTimeEntryColor(entry)}">
                    <div class="centering horizontal layout">
                        <div class="stretch">${displayDistinctWorkarea(app.company, app.profile, entry.position)}</div>
                        <div>${entry.start ? formatTimeDistance(entry.start) : "offen"}</div>
                    </div>
                    <div class="huger time-display">${entry.timeDisplay("planned")}</div>
                    ${entry.comment
                        ? html`
                              <div class="top-margined">
                                  <pre><i class="comment"></i> ${entry.comment}</pre>
                              </div>
                          `
                        : ""}
                </div>

                <button class="large ghost" disabled>
                    <i class="play-circle"></i> Stempeln möglich ${formatTimeDistance(earliestCheckin)}
                </button>

                ${availablePositions.length
                    ? html`
                          <form
                              class="spacing vertical layout hide-when-collapsed"
                              @submit=${this._submitUnplannedShift}
                              id="unplannedShiftForm"
                          >
                              <div class="padded subtle text-centering semibold">oder</div>

                              <select class="large" name="position" ?hidden=${availablePositions.length === 1}>
                                  ${availablePositions.map(
                                      ({ position }) => html`
                                          <option .value=${position.id.toString()}>
                                              ${displayDistinctWorkarea(app.company!, app.profile!, position)}
                                          </option>
                                      `
                                  )}
                              </select>
                              <button class="large primary"><i class="running"></i> Spontane Schicht</button>
                          </form>
                      `
                    : ""}
            </div>
        `;
    }

    private _renderStartable(entry: TimeEntry) {
        if (!app.company || !app.profile || !entry.position) {
            return;
        }
        const late = entry.startPlanned && entry.start < new Date();
        const { trackingViaAppEnabled } = app.getTimeSettings({ timeEntry: entry });
        return html`
            <div class="wrapper spacing vertical layout">
                <div class="subtle horizontally-padded semibold text-centering small-caps">
                    <i class="play-circle"></i> Bereit Zum Stempeln
                </div>

                <div class="large padded box preview" style="--color-highlight: ${app.getTimeEntryColor(entry)}">
                    <div class="centering horizontal layout">
                        <div class="stretch">${displayDistinctWorkarea(app.company, app.profile, entry.position)}</div>
                        <div class="${late ? "blink red colored-text" : ""}">
                            <i class="stopwatch"></i>${entry.startPlanned ? formatTimeDistance(entry.start) : "offen"}
                        </div>
                    </div>
                    <div class="huger time-display">${entry.timeDisplay("planned")}</div>
                    ${entry.comment
                        ? html`
                              <div class="top-margined">
                                  <pre><i class="comment"></i> ${entry.comment}</pre>
                              </div>
                          `
                        : ""}
                </div>

                ${trackingViaAppEnabled
                    ? html`
                          <div class="shining vertical layout hide-when-collapsed">
                              <button class="large primary" @click=${() => this._startShift()}>
                                  <i class="play-circle"></i> Schicht Beginnen
                              </button>
                          </div>
                      `
                    : html`
                          <div class="vertical layout hide-when-collapsed">
                              <button class="large ghost" disabled>
                                  <i class="ban"></i> Stempeln via App Nicht Erlaubt
                              </button>
                          </div>
                      `}
            </div>
        `;
    }

    private _renderActive(entry: TimeEntry) {
        if (!app.company || !app.profile || !entry.position) {
            return;
        }
        const settings = app.getTimeSettings({ timeEntry: entry });
        const breakDur = entry.startBreak && (Date.now() - entry.startBreak.getTime()) / (3600 * 1000);
        // const duration = Math.max(0, (Date.now() - entry.start!.getTime()) / (3600 * 1000));
        return html`
            <div class="wrapper spacing vertical layout">
                <div class="subtle horizontally-padded semibold text-centering small-caps">
                    <i class="clock"></i> Aktive Schicht
                </div>

                <div
                    class="large padded box horizontal layout"
                    style="--color-highlight: ${app.getTimeEntryColor(entry)}"
                >
                    <div class="stretch">
                        <div class="stretch">${displayDistinctWorkarea(app.company, app.profile, entry.position)}</div>
                        <div class="huger time-display">${entry.timeDisplay("planned")}</div>
                        ${entry.comment
                            ? html`
                                  <div class="top-margined">
                                      <pre><i class="comment"></i> ${entry.comment}</pre>
                                  </div>
                              `
                            : ""}
                    </div>
                    <div class="larger vertical spacing layout">
                        ${breakDur
                            ? html`
                                  <div class="blink orange box timer">
                                      <i class="coffee"></i>${toDurationString(breakDur + (entry.breakLogged || 0))}
                                  </div>
                              `
                            : html` <div class="blink green box timer"><i class="play-circle"></i>aktiv</div> `}
                    </div>
                </div>

                <div class="spacing vertical layout hide-when-collapsed">
                    ${entry.startBreak
                        ? html`
                              <button class="large orange" @click=${() => this._endBreak()}>
                                  <i class="coffee"></i> Pause Beenden
                              </button>
                          `
                        : html`
                              <button
                                  class="large orange"
                                  ?hidden=${[BreakMode.Auto, BreakMode.Planned].includes(settings.breakMode)}
                                  @click=${() => this._startBreak()}
                              >
                                  <i class="coffee"></i> Pause
                              </button>
                          `}

                    <button class="large primary" @click=${() => this._endShift()} ?disabled=${!!entry.startBreak}>
                        <i class="door-open"></i> Schicht Beenden
                    </button>
                </div>
            </div>
        `;
    }

    private _renderRecent(entry: TimeEntry) {
        if (!app.company || !app.profile || !entry.position) {
            return;
        }
        return html`
            <div class="wrapper spacing vertical layout">
                <div class="subtle horizontally-padded semibold text-centering small-caps">
                    <i class="check"></i> Schicht Abgeschlossen
                </div>

                <div
                    class="large padded box horizontal layout"
                    style="--color-highlight: ${app.getTimeEntryColor(entry)}"
                >
                    <div class="stretch">
                        <div class="stretch">${displayDistinctWorkarea(app.company, app.profile, entry.position)}</div>
                        <div class="huger time-display">${entry.timeDisplay("complete")}</div>
                        ${entry.comment
                            ? html`
                                  <div class="top-margined">
                                      <pre><i class="comment"></i> ${entry.comment}</pre>
                                  </div>
                              `
                            : ""}
                    </div>
                    <div class="vertical spacing layout">
                        <div class="green box timer"><i class="check"></i>${toDurationString(entry.duration)}</div>
                        <div class="orange box timer" hidden>
                            <i class="coffee"></i>${toDurationString(entry.break || 0)}
                        </div>
                    </div>
                </div>
            </div>
        `;
    }

    private _renderNoShift() {
        const availablePositions = app.getUnplannedShiftPositions(app.profile!);

        return html`
            <div class="wrapper spacing vertical layout">
                <div class="horizontally-padded text-centering subtle semibold small-caps">
                    <i class="calendar-times"></i> Keine geplante Schicht gefunden
                </div>

                ${availablePositions.length
                    ? html`
                          <form
                              class="spacing vertical layout"
                              @submit=${this._submitUnplannedShift}
                              id="unplannedShiftForm"
                          >
                              <select class="large" name="position" ?hidden=${availablePositions.length === 1}>
                                  ${availablePositions.map(
                                      ({ position }) => html`
                                          <option .value=${position.id.toString()}>
                                              ${displayDistinctWorkarea(app.company!, app.profile!, position)}
                                          </option>
                                      `
                                  )}
                              </select>
                              <button class="large primary"><i class="running"></i> Spontane Schicht</button>
                          </form>
                      `
                    : ""}
            </div>
        `;
    }

    render() {
        if (!app.company || !app.profile) {
            return;
        }
        const now = new Date();

        const upcoming = this._upcoming;
        const startable = this._startable;
        const active = this._active;
        const recent = this._recent;

        return html`
            <ptc-scroller class="fullbleed" .locked=${!this.expanded}>
                <div class="vertical layout fill-vertically">
                    <div class="vertical end-justifying layout stretch overflow-hidden">
                        <div class="double-padded text-centering hide-when-collapsed">
                            <div>
                                <strong>${formatWeekDay(now)}</strong>, ${now.getDate()}. ${monthNames[now.getMonth()]}
                            </div>
                            <div class="enormous thin">${toTimeString(now)}</div>
                        </div>
                    </div>

                    ${recent && !active ? this._renderRecent(recent) : ""}
                    ${active
                        ? this._renderActive(active)
                        : startable
                          ? this._renderStartable(startable)
                          : upcoming
                            ? this._renderUpcoming(upcoming)
                            : this._renderNoShift()}
                    ${this._renderRosterNotes()}

                    <div class="stretch hide-when-collapsed"></div>
                </div>
            </ptc-scroller>

            <div class="fullbleed centering layout scrim" ?hidden=${!this._loading}>
                <ptc-spinner ?active=${this._loading}></ptc-spinner>
            </div>
        `;
    }
}
