import "./employee-select";
import "./document-tags-input";
import "./date-input";
import { Dialog } from "./dialog";
import { Document, fileExtension, Form, MimeType } from "@pentacode/core/src/model";
import { html } from "lit-html";
import { css } from "lit";
import { customElement, state, query } from "lit/decorators.js";
import { readFileAsDataURL } from "../lib/util";
import { FileInput } from "./file-input";
import { DateInput } from "./date-input";
import { alert } from "./alert-dialog";
import { app } from "../init";
import { CreateOrUpdateDocumentParams } from "@pentacode/core/src/api";
import { DocumentTagsInput } from "./document-tags-input";
import { EmployeeSelect } from "./employee-select";
import { toDateString } from "@pentacode/core/src/util";
import "./popover";
import { DateString } from "@pentacode/openapi/src/units";

@customElement("ptc-edit-document-dialog")
export class EditDocumentDialog extends Dialog<{ document: Document; file?: File; form?: Form } | undefined, Document> {
    @query("form")
    private _form: HTMLFormElement;

    @query("ptc-file-input")
    private _fileInput: FileInput;

    @query("[name='name']")
    private _nameInput: HTMLInputElement;

    @query("ptc-employee-select")
    private _employeeSelect: EmployeeSelect;

    @query("ptc-date-input")
    private _dateInput: DateInput;

    @query("ptc-document-tags-input")
    private _tagsInput: DocumentTagsInput;

    @state()
    private _newFile?: File;

    @state()
    private _document: Document;

    async show({ document, file }: { document: Document; file?: File }) {
        const promise = super.show();
        this._newFile = undefined;

        this._document = document;
        await this.updateComplete;

        if (file) {
            await this._fileSelected(file);
        }

        this._nameInput.value = this._document.name;
        this._employeeSelect.employeeId = this._document.employeeId || null;
        this._dateInput.value = this._document.date;

        if (this._newFile) {
            this._fileInput.file = this._newFile;
        } else if (this._document.url) {
            this._fileInput.file = new File([], `${this._document.name}.${fileExtension(this._document.type)}`);
        }

        this._tagsInput.tags = this._document.tags || [];
        return promise;
    }

    private async _fileSelected(file?: File | null) {
        file = file || this._fileInput.file;

        if (file && file.size > 10e6) {
            alert("Dateien dürfen nicht größer als 10MB sein!", { title: "Datei zu groß", type: "warning" });
            this._fileInput.file = null;
            return;
        }

        if (file) {
            this._nameInput.value = file.name.replace(new RegExp(`.${file.name.split(".").pop()}$`), "");
            if (file.lastModified) {
                this._dateInput.value = toDateString(new Date(file.lastModified));
            }
        }
        this._newFile = file || undefined;
    }

    private async _submit(e: Event) {
        e.preventDefault();

        const data = new FormData(this._form);

        const file = this._fileInput?.file;

        const content = file && this._newFile && (await readFileAsDataURL(file));
        const name = data.get("name") as string;
        const date = data.get("date") as DateString;
        const comment = data.get("comment") as string;
        const tags = this._tagsInput.tags;
        const employeeId = this._employeeSelect.employeeId;

        if (!employeeId) {
            return;
        }
        const employee = app.getEmployee(employeeId)!;

        if (this._document.form) {
            this._document.form.prefillForEmployee(employee);
        }

        this.loading = true;

        try {
            const document = await app.api.createOrUpdateDocument(
                new CreateOrUpdateDocumentParams({
                    id: this._document?.id,
                    employeeId,
                    name,
                    date,
                    comment,
                    content,
                    tags,
                    form: this._document.form,
                    type: file && content ? (file.type as MimeType) : this._document.type,
                })
            );
            this.done(document);
        } catch (e) {
            alert(e.message, { type: "warning" });
        }

        this.loading = false;
    }

    static styles = [...Dialog.styles, DateInput.styles, css``];

    renderContent() {
        return html`
            <form class="double-padded" @submit=${this._submit}>
                <div class="faded bottom-margined">
                    ${this._document.id
                        ? html`<i class="file-pen"></i> Dokument Bearbeiten`
                        : html`<i class="file-circle-plus"></i> Neues Dokument`}
                </div>

                <div class="field">
                    <label>Name</label>
                    <input name="name" required />
                </div>

                <div class="field">
                    <label>Mitarbeiter</label>
                    <ptc-employee-select required></ptc-employee-select>
                </div>

                <div class="horizontal spacing layout">
                    <div class="field">
                        <label>Datum</label>
                        <ptc-date-input name="date" required></ptc-date-input>
                    </div>

                    ${this._document.form
                        ? html`
                              <div class="field stretch collapse">
                                  <label>Formular</label>
                                  <div class="padded box spacing center-aligning horizontal layout">
                                      <div class="stretch collapse ellipsis">
                                          <i class="pen-field"></i>
                                          ${this._document.form.name}
                                      </div>
                                      <div class="tiny pill">Entwurf</div>
                                  </div>
                              </div>
                          `
                        : html`
                              <div class="field stretch collapse">
                                  <label>Datei</label>
                                  <ptc-file-input
                                      @change=${() => this._fileSelected()}
                                      ?required=${!this._document.id && !this._newFile}
                                      readonly
                                  ></ptc-file-input>
                              </div>
                          `}
                </div>

                <div class="field">
                    <label>Schlagwörter</label>
                    <ptc-document-tags-input></ptc-document-tags-input>
                </div>

                <div class="field">
                    <label>Kommentar</label>
                    <textarea name="comment"></textarea>
                </div>

                <div class="top-padded horizontal stretching spacing layout">
                    <button class="primary">Speichern</button>
                    <button class="transparent" type="button" @click=${() => this.done()}>Abbrechen</button>
                </div>
            </form>
        `;
    }
}
